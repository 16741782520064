import Button from 'components/Button';
import style from './AnnouncementBanner.module.css';
import Link from 'next/link';
import { analytics } from 'lib/analytics';
import useAuth from 'hooks/useAuth';

//Can be transformed to a multi-use component through props
export default function AnnouncementBanner() {
  const [user] = useAuth();
  const bannerAnalytics = () => {
    analytics.generic(user, 'API announcement banner clicked');
  };
  return (
    <Link
      className={style.container}
      href={'https://ai.cleeai.com/api#form'}
      target="_blank"
      onClick={bannerAnalytics}
    >
      <div className={style.left}>
        <span className={style.star}>🌟</span>
        <p className={style.headline}>Announcing CleeAI&apos;s API</p>
        <p className={style.ctaText}>Register your interest for</p>
      </div>
      <span className={style.verticalLine}></span>
      <Button className={style.btn} variant="secondary">
        Early access
      </Button>
    </Link>
  );
}
